import $ from 'jquery';

export const navigation = ({ screen_md_min }) => {
  $('button.plone-navbar-toggle').on('click', function() {
    $('body').addClass('menuOpened');
  });

  $('#globalnav-close').on('click', function() {
    $('body').removeClass('menuOpened');
  });

  $(document).on('click', function(e) {
    if (
      !$(e.target).closest('#portal-mainnavigation').length &&
      !$(e.target).closest('button.plone-navbar-toggle').length &&
      $(window).width() < screen_md_min
    ) {
      $('#portal-mainnavigation').removeClass('open');
      $('body').removeClass('menuOpened');
    }
  });
};
