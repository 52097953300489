import $ from 'jquery';

export const formButtonSave = () => {
  $(document).on('click', '#form-buttons-save', function(e) {
    setTimeout(function() {
      $(e.target).attr('disabled', 'disabled');
      $(e.target)
        .closest('.formControls')
        .find('#form-buttons-cancel')
        .attr('disabled', 'disabled');
      $(e.target)
        .closest('.formControls')
        .addClass('loading');
    }, 0);
  });
};
