import $ from 'jquery';
import { navigation } from './navigation';
import { formButtonSave } from './formButtonSave';

$(() => {
  const minWidths = {
    screen_sm_min: 768,
    screen_md_min: 992,
  };
  const maxWidths = {
    screen_xs_max: minWidths.screen_sm_min - 1,
  };
  const options = {
    ...minWidths,
    ...maxWidths,
  };

  navigation(options);
  formButtonSave();
});
